<template>
   <div>
      <section class="self_sec reste-pas">
         <div class="container-fluid">
            <div class="row">
               <div class="col-md-6">
                  <div class="login_signup_form reset-pwd py_60 mt_10">
                     <!-- <FlashMessage :error="error" v-if="checkType" class="text-center" /> -->
                     <h2 class="text_black font_size_36 mb_40">Reset Password</h2>
                     
                     <form method="post" @submit.prevent="resetPassword" data-vv-scope="resetPasswodForm">
                        <div class="form-group">
                           <label class="font_size_24 mb_20 font_bold">Email</label>
                           <input type="text" placeholder="Email" class="form-control" name="email" v-model="reset_data.email" v-validate="'required'">
                           <small class="error text-danger" v-show="errors.has('resetPasswodForm.email')">
                           {{ errors.first("resetPasswodForm.email") }}
                           </small>
                        </div>
                        <div class="form-group">
                           <label class="font_size_24 mb_20 font_bold">Password<svg style="margin-left: 5px;" v-tooltip=' "Password must contains with mix of minimum 15 characters and maximum 64 characters in combination of at least one uppercase, lowercase, digits, special character. "'  xmlns="http://www.w3.org/2000/svg" data-name="Group 13109" width="16" height="16" viewBox="0 0 14 18.346" ><path data-name="Path 9426" d="M0 0h14v14H0z" style="fill: none" ></path><path data-name="Path 9427" d="M9 16a7 7 0 1 1 7-7 7 7 0 0 1-7 7zm0-1.4A5.6 5.6 0 1 0 3.4 9 5.6 5.6 0 0 0 9 14.6zm-.7-9.1h1.4v1.4H8.3zm0 2.8h1.4v4.2H8.3z" transform="translate(-2 -1.654)" style="fill: rgb(109, 109, 109)" ></path></svg></label>
                           <input type="password" placeholder="Password" class="form-control" name="Password" v-model="reset_data.password" v-validate="{ required: true, min: 15, max: 64, regex: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).*$/ }" ref="password" onpaste="return false;" oncopy="return false;" ondrop="return false;"> 
                           <small class="error text-danger" v-show="errors.has('resetPasswodForm.Password')" >
                           {{ errors.first("resetPasswodForm.Password") }}
                           </small>
                        </div>
                        <div class="form-group">
                           <label class="font_size_24 mb_20 font_bold">Confirm Password</label>
                           <input type="password" placeholder="Password confirmation" class="form-control" name="password_confirmation" v-model="reset_data.password_confirmation" v-validate="'required|confirmed:password'" data-vv-as="password" onpaste="return false;" oncopy="return false;" ondrop="return false;">
                           <small class="error text-danger" v-show="errors.has('resetPasswodForm.password_confirmation')">
                           {{ errors.first("resetPasswodForm.password_confirmation") }}
                           </small>
                        </div>
                        <div class="mt_68 d-flex justify-content-between align-items-center btn_link_box">
                           <div>
                              <button type="submit" class="btn btn_primary">
                                 Reset Password
                              </button>
                           </div>
                        </div>
                     </form>
                  </div>
               </div>
               <div class="col-md-6 p-0">
                  <div class="self_img">
                     <img src="../../assets/home/images/schedule_img.png" alt="img">
                  </div>
               </div>
            </div>
         </div>
      </section>
   </div>
</template>

<script>
// import FlashMessage from "@/components/FlashMessage";
import commonFunction from "../../mixin/commonFunction";

export default {
//    components: {
//     FlashMessage,
//   },
  name: "ResetPassword",
  mixins: [commonFunction],
  data() {
     return {
         reset_data: {
            token: this.$route.query.token,
            email: '',
            password: '',
            password_confirmation: '',
         },
         checkResetToken:false,
         checkType: true,
         error:null,
     };
  },
   mounted() {
      this.checkToken();
    },
   methods: {
      resetPassword() {
        var _this = this;
        this.$parent.$refs.fullpageloader.is_full_page_loader = true;
        _this.$validator.validateAll("resetPasswodForm").then((isValid) => {
          if (isValid) {
            if (_this.reset_data.token) {
              let config = {
                method: "post",
                url: process.env.VUE_APP_API_URL + "/api/reset-password",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${window.localStorage.getItem(
                  "userToken"
                )}`,
                },

                data: {request_data : this.encodeAPIRequest(_this.reset_data)},
              };
              _this
                .axios(config)
                .then(({data}) => {
                  this.$parent.$refs.fullpageloader.is_full_page_loader = false;
                  _this.$toast.success(data.message, {
                    position: "top-right",
                  });
                  _this.$router.push({
                    name: "login"
                  });
                })
                .catch(({
                  response
                }) => {
                  this.$parent.$refs.fullpageloader.is_full_page_loader = false;
                  // this.error = response.data.error;
                  _this.$toast.error(response.data.error, {
                     position: "top-right",
                  });
                  
                  // _this.$toast.error(response.data.error, {
                  //   position: "top-right",
                  // });
                });
            } 
          } else {
            console.log("Error In Validation", _this.errors);
            this.$parent.$refs.fullpageloader.is_full_page_loader = false;
          }
        });
      },
      checkToken(){
          var _this = this;
         //alert(_this.$route.query.token);
        this.$parent.$refs.fullpageloader.is_full_page_loader = true;
            if (_this.$route.query.token) {
              let config = {
                method: "post",
                url: process.env.VUE_APP_API_URL + "/api/check-reset-token",
                data:{
                   token: _this.$route.query.token
                },
              };
              _this
                .axios(config)
                .then(({
                  data
                }) => {
                  if(data == 0){
                     _this.$toast.error("Link is expired. Please try again.", {
                     position: "top-right",
                     });
                     _this.$router.push({
                     name: "forgotPassword"
                     });
                  }
                   this.$parent.$refs.fullpageloader.is_full_page_loader = false;                 
                })
                .catch(({
                  response
                }) => {
                  _this.$toast.error(response.data.error, {
                     position: "top-right",
                  });
                  this.$parent.$refs.fullpageloader.is_full_page_loader = false;
                });
            } 
         
       
      }
   },
};
</script>